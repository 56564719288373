export enum UserStatus {
  Unknown = -1,
  No = 0,
  Newbie = 1,
  Apprentice = 2,
  Master = 3,
  Grandmaster = 4,
  Guru = 5,
  Admin = 10,
  Shop = 11,
  OfficialRepresentative = 100,
  OfficialRepresentative2 = 101,
}



export const UserStatusTr: Record<UserStatus, string> = {
  [UserStatus.Unknown]: '',
  [UserStatus.No]: '',
  [UserStatus.Newbie]: 'universal_status_newbie',
  [UserStatus.Apprentice]: 'universal_status_apprentice',
  [UserStatus.Master]: 'universal_status_master',
  [UserStatus.Grandmaster]: 'universal_status_grandmaster',
  [UserStatus.Guru]: 'universal_status_guru',
  [UserStatus.Admin]: 'universal_status_admin',
  [UserStatus.Shop]: 'universal_status_shop',
  [UserStatus.OfficialRepresentative]: 'universal_status_official_representative',
  [UserStatus.OfficialRepresentative2]: 'universal_status_official_representative',
}